<template>
    <div class="container">
        <div class="intro-header">
            <div class="row">
                <div class="col-12 center">
                    <img
                        alt="Burning Galaxy"
                        src="/images/another_earth.svg"
                        width="1000"
                        height="78.92"
                        class="ae-project"
                    />
                    <!-- <h2>THE <strong class="bold-700">METAVERSE</strong></h2> -->
                </div>
            </div>
            <div class="row">
                <div class="col-3" />
                <div class="col-6 center">
                    <p>
                        {{ $t("aeProject.text1") }}
                    </p>
                </div>
                <div class="col-23" />
            </div>
        </div>

        <div class="row detail">
            <div class="col-4 center intro">
                <h3 class="right">{{ $t("aeProject.section.text1.title") }}</h3>
                <p class="reality">
                    {{ $t("aeProject.section.text1.value") }}
                </p>
            </div>
            <div class="col-4 center intro">
                <h3 class="center">
                    {{ $t("aeProject.section.text2.title") }}
                </h3>
                <p class="diversity">
                    {{ $t("aeProject.section.text2.value") }}
                </p>
            </div>
            <div class="col-4 center intro">
                <h3 class="left">{{ $t("aeProject.section.text3.title") }}</h3>
                <p class="scalability">
                    {{ $t("aeProject.section.text3.value") }}
                </p>
            </div>
        </div>

        <div
            class="row detail"
            v-bind:style="{
                marginTop: '0px',
            }"
        >
            <div class="col-4 center intro">
                <h3 class="right">{{ $t("aeProject.section.text4.title") }}</h3>
                <p class="reality">
                    {{ $t("aeProject.section.text4.value") }}
                </p>
            </div>
            <div class="col-4 center intro">
                <h3 class="center">
                    {{ $t("aeProject.section.text5.title") }}
                </h3>
                <p class="diversity">
                    {{ $t("aeProject.section.text5.value") }}
                </p>
            </div>
            <div class="col-4 center intro">
                <h3 class="left">{{ $t("aeProject.section.text6.title") }}</h3>
                <p class="scalability">
                    {{ $t("aeProject.section.text6.value") }}
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-3" />
            <div class="col-7 center">
                <p
                    v-bind:style="{
                        fontSize: '16px',
                        marginTop: '30px',
                    }"
                >
                    {{ $t("aeProject.text2") }}
                </p>
            </div>
            <div class="col-23" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Section2",
};
</script>
