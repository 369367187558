<template>
  <div>
    <div class="for-mobile">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>CONTACT US</h2>
            <div class="email">
              <a href="mailto:contact@burninggalaxy.com"
                >contact@burninggalaxy.com</a
              >
            </div>
            <div class="title bold-700">BURNING GALAXY CHINA</div>
            <div class="address mb">
              Room 1903, 398 Guiyang Rd Shanghai, China, 200090
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="for-pc">
      <div class="container">
        <div class="row wrap">
          <div class="col-4 center">
            <h2>CONTACT US</h2>
            <div class="email">
              <a href="mailto:contact@burninggalaxy.com"
                >contact@burninggalaxy.com</a
              >
            </div>
            <div class="title bold-700">BURNING GALAXY CHINA</div>
            <div class="address mb">
              Room 1903, 398 Guiyang Rd Shanghai, China, 200090
            </div>
          </div>
          <div class="col-8 center">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GOOGLE_MAP_EMBED_URL_KOREA, GOOGLE_MAP_EMBED_URL_US } from "../const";

export default {
  name: "Contact",
  created() {
    this.GOOGLE_MAP_EMBED_URL_KOREA = GOOGLE_MAP_EMBED_URL_KOREA;
    this.GOOGLE_MAP_EMBED_URL_US = GOOGLE_MAP_EMBED_URL_US;
    this.locale = this.$root.$i18n.locale;
  },
  data: function () {
    return {
      GOOGLE_MAP_EMBED_URL_KOREA,
      GOOGLE_MAP_EMBED_URL_US,
      locale: this.$root.$i18n.locale,
    };
  },
  watch: {
    "$i18n.locale": function (newVal) {
      this.locale = newVal;
    },
  },
};
</script>
