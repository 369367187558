<template>
  <div>
    <Header @goto="goto" />
    <section class="section1-container flex" ref="section1">
      <Section1 />
    </section>
    <section class="section2-container section-container" ref="section2">
      <Section2 />
    </section>
    <section class="section3-container section-container" ref="section3">
      <Section3 />
    </section>
    <section class="section4-container section-container" ref="section4">
      <Section4 />
    </section>
    <section class="section5-container section-container" ref="section5">
      <Section5 />
    </section>
    <section class="contact-container" ref="section6"><Contact /></section>
    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Section1 from "./Section1.vue";
import Section2 from "./Section2.vue";
import Section3 from "./Section3.vue";
import Section4 from "./Section4.vue";
import Section5 from "./Section5.vue";
import Contact from "./Contact.vue";
import Footer from "./Footer.vue";

export default {
  name: "Main",
  components: {
    Header,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Contact,
    Footer,
  },
  methods: {
    goto(refName) {
      var top = this.$refs[refName].offsetTop;
      window.scrollTo({
        top,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
