<template>
  <div class="container">
    <div class="row">
      <div class="col-7 intro-header">
        <h2>
          OUR
          <strong>STORY</strong>
        </h2>
        <h3>{{ $t("outFootSteps.text1") }}</h3>
        <div class="foot-steps">
          {{ $t("outFootSteps.text2") }}
          <div>
            <strong>{{ $t("outFootSteps.text3") }}</strong>
          </div>
        </div>
      </div>
      <div class="col-5">
        <ul>
          <li>
            <div class="year">2018</div>
            <div class="text">/08 {{ $t("outFootSteps.text4") }}</div>
            <div class="text">/10 {{ $t("outFootSteps.text5") }}</div>
          </li>
          <li>
            <div class="year">2021</div>
            <div class="text">/04 {{ $t("outFootSteps.text6") }}</div>
            <div class="text">/09 {{ $t("outFootSteps.text7") }}</div>
            <div class="text">/11 {{ $t("outFootSteps.text8") }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section4",
};
</script>
