<template>
  <div class="header-container">
    <header class="header">
      <div class="container m-wrap">
        <div class="row">
          <div class="col-12 flex nav-wrap">
            <button
              type="button"
              class="m-nav-button"
              @click="isNavOpen = !isNavOpen"
            >
              <img alt="s" src="/images/hamburger.svg" width="28" height="28" />
            </button>
            <div class="flex">
              <button
                type="button"
                class="ci"
                @click="$emit('goto', 'section1')"
              >
                <img
                  alt="Burning Galaxy"
                  src="/images/logo.svg"
                  width="117"
                  height="28"
                />
              </button>
              <ul :class="{ 'is-open': isNavOpen, nav: true }">
                <li>
                  <button
                    type="button"
                    class="btn-nav"
                    @click="$emit('goto', 'section2')"
                  >
                    {{ $t("sections[0]") }}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-nav"
                    @click="$emit('goto', 'section3')"
                  >
                    {{ $t("sections[1]") }}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-nav"
                    @click="$emit('goto', 'section4')"
                  >
                    {{ $t("sections[2]") }}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-nav"
                    @click="$emit('goto', 'section5')"
                  >
                    {{ $t("sections[3]") }}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="btn-nav"
                    @click="$emit('goto', 'section6')"
                  >
                    {{ $t("sections[4]") }}
                  </button>
                </li>
              </ul>
            </div>
            <div
              class="lang"
              @mouseout="isOpen = false"
              @mouseover="isOpen = true"
            >
              <div class="flex lang-select">
                <button type="button" @click="isOpen = !isOpen">
                  <img alt="lang" src="/images/globe.svg" width="30rem" />
                  <span>{{ langLabels[$root.$i18n.locale] }}</span>
                </button>
              </div>
              <div :class="{ 'is-open': isOpen, dropdown: true }">
                <label class="check-lang flex">
                  <input
                    type="radio"
                    name="lang"
                    v-model="$i18n.locale"
                    :value="langs[0]"
                  />
                  <span>English</span>
                  <i />
                </label>
                <label class="check-lang flex">
                  <input
                    type="radio"
                    name="lang"
                    v-model="$i18n.locale"
                    :value="langs[1]"
                  />
                  <span>한국어</span>
                  <i />
                </label>
                <label class="check-lang flex">
                  <input
                    type="radio"
                    name="lang"
                    v-model="$i18n.locale"
                    :value="langs[2]"
                  />
                  <span>中文</span>
                  <i />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  data: function () {
    return {
      isOpen: false,
      isNavOpen: false,
      langs: ["en", "ko", "cn"],
      langLabels: {
        ko: "한국어",
        en: "English",
        cn: "中文",
      },
    };
  },
  mounted() {
    let currentLang = "en";
    if (navigator.language.startsWith("ko")) {
      currentLang = "ko";
    } else if (navigator.language.startsWith("zh")) {
      currentLang = "cn";
    }
    this.$i18n.locale = currentLang;
  },
};
</script>
