<template>
  <section class="footer-container">
    <div class="for-pc">
      <div class="container">
        <div class="row">
          <div class="col-12 center intro-header">
            <img alt="Burning Galaxy" src="/images/footer.png" />
            <p>Copyright @ 2021 Burning galaxy Ltd. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="for-mobile">
      <div class="intro-header">
        <img alt="Burning Galaxy" src="/images/footer.png" />
      </div>
      <div class="copyright">
        Copyright @ 2021 Burning galaxy Ltd. All rights reserved.
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",
};
</script>
