import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./locales/en.json";
import ko from "./locales/ko.json";
import cn from "./locales/cn.json";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en, ko, cn },
});
