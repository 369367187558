<template>
  <div>
    <div class="container">
      <div class="intro-header">
        <div class="row">
          <div class="col-12 center">
            <h2 class="title">
              WE ARE
              <strong>GLOBAL!</strong>
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 center">
            <p class="text">
              {{ $t("weAreGlobal") }}
            </p>
          </div>
        </div>
      </div>
      <div class="row for-pc">
        <div class="col-12 center">
          <img alt="AE PROJECT" src="/images/map.svg" class="full-img" />
        </div>
      </div>
    </div>
    <div class="for-mobile">
      <div>
        <img alt="AE PROJECT" src="/images/map.svg" class="full-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section3",
};
</script>
