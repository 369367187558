<template>
    <div class="main" ref="main">
        <div class="for-pc">
            <VueSlickCarousel
                :arrows="true"
                ref="carousel"
                @afterChange="afterChange"
            >
                <div class="video">
                    <video-player
                        class="vjs-custom-skin"
                        ref="mainVideo"
                        :options="playerOptions"
                        :playsinline="true"
                        @ended="onPlayerEnded($event)"
                    >
                    </video-player>
                </div>
                <div>
                    <picture>
                        <source srcSet="/images/hire.webp" type="image/webp" />
                        <img src="/images/hire.png" />
                    </picture>
                </div>
            </VueSlickCarousel>
        </div>
        <div class="for-mobile">
            <VueSlickCarousel
                :arrows="true"
                :autoplay="true"
                :autoplaySpeed="5000"
            >
                <div>
                    <picture>
                        <source srcSet="/images/main2.webp" type="image/webp" />
                        <img src="/images/main2.png" />
                    </picture>
                </div>
                <div>
                    <picture>
                        <source
                            srcSet="/images/hire-m.webp"
                            type="image/webp"
                        />
                        <img src="/images/hire-m.png" />
                    </picture>
                </div>
            </VueSlickCarousel>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

const options = {
    autoplay: true,
    fluid: true,
    muted: true,
    language: "en",
    controls: false,
    playbackRates: [0.7, 1.0, 1.5, 2.0],
};

export default {
    name: "Section1",
    components: { VueSlickCarousel, videoPlayer },
    mounted() {
        this.width = this.$refs.main.clientWidth;
        this.height = this.$refs.main.clientHeight;
        window.addEventListener("resize", this.handleResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.width = this.$refs.main.clientWidth;
            this.height = this.$refs.main.clientHeight;
        },
        onPlayerEnded(video) {
            this.endedVideo = video;
            this.$refs.carousel.next();
        },
        afterChange(slideIndex) {
            this.currentSlideIndex = slideIndex;
            if (this.activateTimeOut) {
                clearTimeout(this.activateTimeOut);
            }
            if (slideIndex === 1) {
                this.activateTimeOut = setTimeout(() => {
                    if (this.currentSlideIndex === 1) {
                        // 5초 후에도 여전히 1이면
                        if (this.endedVideo) {
                            this.endedVideo._currentTime = 0;
                            this.endedVideo.play();
                        }
                        console.log(this.$refs.carousel);
                        this.$refs.carousel.next();
                    }
                }, 5000);
            }
        },
    },
    data() {
        return {
            width: 0,
            height: 0,
            videoOption: {
                width: this.width,
                height: this.height,
                loop: true,
                autoplay: true,
                poster: "/images/freeze.png",
                ...options,
            },
            // videojs options
            playerOptions: {
                ...this.videoOption,
                sources: [
                    {
                        type: "video/mp4", // mp4
                        src: "/video/intro.mp4",
                    },
                ],
            },
        };
    },
    watch: {
        width: function (newValue) {
            this.videoOption = {
                height: this.height,
                width: newValue,
                ...options,
            };
            this.playerOptions = {
                ...this.videoOption,
                sources: [
                    {
                        type: "video/mp4", // mp4
                        src: "/video/intro.mp4",
                    },
                ],
            };
        },
    },
};
</script>
