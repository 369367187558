<template>
  <div class="container">
    <div class="row">
      <div class="col-6 center intro-header">
        <h2>
          WE NEED
          <strong>YOU</strong>
        </h2>
        <h3>{{ $t("weNeedYou.text1") }}</h3>
        <p>
          {{ $t("weNeedYou.text2") }}
        </p>
      </div>
      <div class="col-6">
        <div class="link-recruit-wrap">
          <div class="anim-box"/>
          <a
            :href="goToHref"
            class="link-recruit"
            target="_blank"
          >
            <span class="link-recruit-wrapper">
              <h2>
                Go to
                <strong>Recruiting Page</strong>
              </h2>
              <img alt="lang" src="/images/arrow-right.svg" class="img-arrow"/>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Section5",
  computed: {
    goToHref: {
      get() {
        return (this.$root.$i18n.locale === "ko") ?
          "http://www.gamejob.co.kr/Company/Detail?M=34330052" :
          "mailto:contact@burninggalaxy.com"
      }
    },
  },
};
</script>
