import Vue from "vue";
import i18n from "./i18n";
import "./assets/css/reset.css";
import "./assets/css/simple-grid.min.css";
import "./assets/css/style.css";
import App from "./App.vue";

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  i18n,
}).$mount("#app");
